import { QueryException } from '_backend/core/cqs-query/exception';

export type SerializedQueryException = {
  __type: 'SerializedError';
  status: number | null;
  errorClass: string | null;
  message: string | null;
  url: string | null;
  method: string | null;
  stackTrace: string | null;
  previousErrorMessage: string | null;
};

/**
 * Serialize an http exception to json
 */
export const serializeQueryException = (e: QueryException): SerializedQueryException => {
  const { httpStatus = null, message = null, previousError = null } = e.props ?? {};
  const { stack } = e;

  return {
    __type: 'SerializedError',
    status: httpStatus,
    message,
    previousErrorMessage: previousError?.message ?? null,
    url: null,
    method: null,
    stackTrace: stack ?? null,
    errorClass: e.constructor?.name ?? 'Error',
  };
};
